#template-tabs-tab-GeneralInfo.active{
  color: aliceblue;
}

#template-tabs-tab-Character.active{
  color: aliceblue;
}

#template-tabs-tab-TouchObject.active{
  color: aliceblue;
}

#template-tabs-tab-DropObject.active{
  color: aliceblue;
}

.tabContent{
  margin-top: 20px;
}