.wrapper-view-unit {
  width: 100%;
}

.content-select {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.content-button-search {
  width: 10%;
}

.wrapper-selects {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.wrapper-content-select {
  width: 48%;
}

.wrapper-search {
  
}

.wrapper-table {
  margin-top: 2em;
}

