.invisibleMenu{
  background-color: rgba(0,0,0,0) !important;
  border: rgba(0,0,0,0) !important;

}
.invisibleMenu:hover{
  background-color: rgba(0,0,0,0) !important;
  border: rgba(0,0,0,0) !important;
}
.invisibleMenu:focus{
  box-shadow: 0 0 0 0.2rem rgba(0,0,0,0) !important;
}
.downloadPdfLink{
  color: white !important;
  text-decoration: none !important;
}
.downloadPdfLink:hover{
  color:white !important;
  text-decoration: none !important;
}