.loginContent{
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginForm{
  display: flex;
  flex-direction: column;
}

.textInput, .loginButton{
  width: 252px;
}

.logoImageCol{
  display: flex;
  align-items: center;
  justify-content: center;
}