body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-transparent{
  display: inline-block;
  font-weight: 400;
  color: #4b6989;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.container{
  padding: 0px !important;
}

.buttonMargin{
  margin: 5px;
}

.navbar{
background-color: #27466c !important;
}
button{
  border-radius: 20px !important;
}

h2{
  color: #326798;
}

h4{
  color: #2a5780;
}

h3{
  color: #1a3e5f;
}

b{
  color: #275789;
}

.card-alt{
  padding: 25px !important;
  background-color: #FFF;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(171,171,171,1);
  -moz-box-shadow: 0px 3px 5px 0px rgba(171,171,171,1);
  box-shadow: 0px 3px 5px 0px rgba(171,171,171,1);
}

.card-alt-2{
  padding: 25px !important;
  background-color: #f7f7f7;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(171,171,171,1);
  -moz-box-shadow: 0px 3px 5px 0px rgba(171,171,171,1);
  box-shadow: 0px 3px 5px 0px rgba(171,171,171,1);
}

.dropdown-toggle{
  font-size: 14px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-control{
  margin-top:5px;
}

.btn-primary{
  background-color: #27466c!important;
  border: 1px solid #27466c!important;
}

.btn-success{
  background-color: #f2af2b!important;
  border: 1px solid #f2af2b!important;
}

.btn-warning{
  background-color: #f2af2b!important;
  border: 1px solid #f2af2b!important;
}

.modal-dialog-w {
  max-width: 780px !important;
}

.flex {
  display: flex;
}

/* Style Improvements Ene-2023 */
div.navbar-nav > div.nav-item > a.nav-link {
  font-size: 14px;
}

.dropdown-item {
  padding: 0.25rem 1rem;
  font-size: 14px;
}

.dropdown-item.active, .dropdown-item:active {
  color: #27466c;
  background-color: #c5d4e6;
}

a.nav-link.active, .dropdown-toggle.nav-link.active {
  background-color: #406490 !important;
  border-radius: 5px;
}

/* fix Bottom */
.btn {
    -webkit-font-smoothing: antialiased;
  font-size: .875rem;
  letter-spacing: .25px;
  box-sizing: border-box;
  font-weight: 500;
  padding: 3px 16px 5px 16px;
  box-shadow: inset 0 0 0 1px #dadce0;
  border: 1px solid #dadce0;
    -webkit-border-radius: 18px;
  border-radius: 18px;
    -webkit-box-shadow: none;
  box-shadow: none;
  display: inline-block;
  overflow: hidden;
  text-shadow: none;
  vertical-align: bottom;
}

.btn svg {
  margin-right: 8px
}

.btn-warning {
  color: #FFF;
}

/* fix Card */
.card-alt {
  padding: 25px 20px !important;
  background-color: #FFF;
  box-shadow: 0px 3px 5px 0px rgb(228 228 228);
  border-radius: 12px;
}

/* fix Card */
.card-alt-2 {
  padding: 25px 0 !important;
  background-color: #FFF;
  box-shadow: none;
}

/* Clear card Box shadow */
.clear-box-shadow .card-alt {
  box-shadow: none;
}

/* border left content create lesson */
.border-left-colNine .col-sm-9 {border-left: 1px solid #ebebeb;}

body {background-color: #f8fbff;}

/* Space Radio button on create lesson */
.space-radioButton .form-check {
  margin-left: 15px;
}

/* Region Add Code */
.Region-AddCode--desc span {display: none;}
.Region-AddCode .row, .Region-AddCode.row {align-items: center;}
.btn.btn-trash {
  background-color: transparent;
  border: 0;
  color: #dc3545;
}

.btn.btn-trash:focus {box-shadow: none;}
.btn.btn-trash:hover {color: #8f959b;}

.Region-AddCode__ModalButton {justify-content: end;}
.Region-AddCode__ModalButton .btn {margin-left: 10px;}
.Row-AlignCenter .row {align-items: center;}
.Region-AddCode__ModalButton .btn-light {color: #8f959b; font-size: 12px;}

.title-block {display: block;}

/*Floatting Button */
.wrapper-button-flottingLesson {
  padding: 10px;
  background-color: #FFF;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgb(228 228 228);
}

.wrapper-button-flottingLesson span {
  color: #777c81;
  font-size: 13px;
}

.wrapper-button-flottingLesson .invisibleMenu {
  padding: 0;
}

.wrapper-button-flottingLesson svg {
  padding: 8px !important;
  width: 35px !important;
  height: 35px !important;
  margin: 5px !important;
  cursor: pointer;
}

.wrapper-button-flottingLesson svg.fa-save {
  background-color: #f2af2b !important;
}

.wrapper-button-flottingLesson svg.fa-eye {
  background-color: #27466c !important;
}

.wrapper-button-flottingLesson svg.fa-save:hover,
.wrapper-button-flottingLesson svg.fa-eye:hover,
.wrapper-button-flottingLesson .invisibleMenu.btn-primary:hover svg {
  background-color: #8f959b !important;
}

.wrapper-button-flottingLesson .invisibleMenu.btn-primary:hover {
  background-color: transparent !important;
}

.RegionStatus { display: flex; align-items: center;}
.RegionDesc {padding-top: 1em; padding-bottom: 1em; background-color: #f2f6fb; margin-bottom: 0.5em;}

.buttonPDF.btn-primary,
.buttonPDF.btn-primary:hover {
  padding: 0;
  background-color: transparent !important;
  border: none !important;
  margin-left: 10px;
}

.buttonPDF.btn-primary a.downloadPdfLink {
  color: #275789 !important;
  text-transform: uppercase;
  font-weight: 600;
}

.buttonPDF.btn-primary a.downloadPdfLink:hover {
  color: #8f959b !important;
}

@media only screen and (max-width: 860px) {
  .RegionDesc .col-2 {
    max-width: 100%; 
    flex:100%;
  }
  .RegionDesc .col-2 p {margin-bottom: 0;}
}

.Region-ButtonAudio .col .itemContainer div {
  margin: auto !important;
  justify-content: space-between;
}

.Region-ButtonAudio .col .itemContainer div svg {
  padding: 8px !important;
  color: #FFF;
}

.Region-ButtonAudio .col .itemContainer div {
  width: 40px !important;
  height: 40px !important;
  margin-bottom: 10px !important;
}

.fix-RegionDef .btn-danger,
.fix-dangerBtn .btn-danger {
  color: #dc3545;
  background-color: transparent;
  border: 0;
  vertical-align: middle;
  font-size: 16px;
  margin-top: -6px;
  cursor: pointer;
}

.fix-RegionDef .btn-danger:focus,
.fix-dangerBtn .btn-danger:focus {
  box-shadow: none;
}

.fix-dangerBtn .btn-danger:hover {
  color: #8f959b
}

.fix-RegionDef input {
  margin-bottom: 15px;
}

.fix-RegionDef .card-alt-2 {
  margin-bottom: 20px;
}

.messageNoTemplate {
  color: #d5963b;
  font-weight: 500;
  font-style: italic;
}

.RegionTabs {
  margin-bottom: 10px;
  font-size: 14px;
}

.RegionTabs #comment-tabs-tab-Received.active,
.RegionTabs #comment-tabs-tab-Sent.active {
  background-color: transparent !important;
  border: 0;
  border-bottom: 4px solid #27466c;
  color: #27466c;
}

.FixLabel {
  font-weight: 600;
  margin-top: 20px;
}

.FixRadioButton .booleanSelectorItem {
  margin-left: 0;
  margin-right: 50px;
}

.FixRadioButton .booleanSelectorItem input {margin-right: 10px;}
.InstructionsPrimary {font-weight: bold; margin-top: 20px;}

.addedFieldButtonContainer {justify-content: flex-start !important;}
.addedFieldButtonContainer .addedFieldButton {width: auto;}

.col-w-full .col-md-10 {
  flex: 0 0 100%;
  max-width: 100%;
}

.text-danger {
  font-size: 12px;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #6c8799 !important;
  background-color: #fcfcff !important;
  border: 1px solid #dee2e6;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: #7fb2e3 !important;
  border-color: #73a7db !important;
}

.dropzone-type-selected {
  background-color: aliceblue;
  border-radius: 15px;
}