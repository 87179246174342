.pageControls{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
  }

.pdfContainer{
    display: flex;
    justify-content: center;
}
.downloadPdfLink{
    color: white !important;
}
.downloadPdfLink:hover{
    color:white !important;
}
.downloadPdfButton{
    float: right;
}