.wrapper-view-unit {
  width: 100%;
}

.content-select {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.content-button-search {
  width: 10%;
}

.wrapper-selects {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.wrapper-table {
  margin-top: 2em;
}

/* Style Improvements Ene-2023 */
.td-wrapper  {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.td-wrapper div {
  padding-left: 10px;
}

.fixing-table-code {
  font-size: 14px;
}

/* inputs select on 4 columns */
.col-four-select .wrapper-content-select {
  width: 20%;
}

/* Table General */
.table-responsive td, .table td { font-size: 14px;}
.table-responsive th, .table th { font-size: 13px;}
.table thead th { background: #ecf1f6;}
.table-striped tbody tr:nth-of-type(odd) {background-color: rgb(0 0 0 / 3%);}
.table-bordered td, .table-bordered th {border: 1px solid #eeeeee;}

.wrapper-user-filter div {
  width: 45%;
}

/* Butons */
.btn-outline-primary {
  color: #46658c;
}

.btn-outline-primary:hover,
.btn-primary:hover,
.btn-warning:hover {
  color: #fff;
  background-color: #8f959b !important;
  border-color: #8f959b !important;
}

.pageControls button.btn { 
  border-radius: 4px !important; 
  padding-left: 12px;
  padding-right: 12px;
}

/* Titles */
h4 { color: #27466c;}
h3 svg {margin-right: 15px; font-size: 22px; color: #b7b7b7;} 