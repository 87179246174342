.conten-unit-page {
  display: flex;
  justify-content: space-between;
}

.conten-unit-page .text-danger {
  color: #b13c3c;
  font-size: 11px;
  margin: auto;
  padding: 0.25em;
  text-align: right;
}